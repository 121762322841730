import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  TextField, Button,
} from '@pik/pik-ui';

import { sendAuth } from '../../../../services/auth/actions';
import { changeScreenState } from '../../../../services/app/actions';
import handlePressEnter from '../../../../common/handlePressEnter';
import { screenStates } from '../../../../services/app/constants';

import {
  FormWrapper,
  FieldWrapper,
  PasswordRecoveryButton,
  Error,
} from './styles';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';

class AuthForm extends Component {
  static propTypes = {
    sendAuthAction: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    changeScreenStateAction: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  static defaultProps = {
    error: null,
  };

  state = {
    isRendered: false,
    login: '',
    password: '',
    captcha: null,
    validate: {
      login: false,
      password: false,
    },
  };

  componentDidMount() {
    this.unbindPressEnter = handlePressEnter(this.handleEnterClick);
    this.setState({ isRendered: true });
  }

  componentWillUnmount() {
    this.unbindPressEnter();
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value }, () => {
      this.validateOnChange(name, value);
    });
  };

  handleValidate = (name, isValid) => {
    const { validate } = this.state;
    this.setState({ validate: { ...validate, [name]: isValid } });
  };

  handleEnterClick = () => {
    const { validate: { login, password } } = this.state;

    if (login && password) {
      this.sendAuthData();
    }
  };

  handleRecoveryButtonClick = () => {
    const { changeScreenStateAction } = this.props;
    changeScreenStateAction(screenStates.PASSWORD_RECOVERY);
  };

  sendAuthData = () => {
    const { sendAuthAction } = this.props;
    const { login, password, captcha } = this.state;

    if (login.length && password.length) {
      sendAuthAction({ login, password, captcha, captchaType: 'pei5iLaiqu0Gohz1ahJohThaChah2soo3faes0iZira4vog1Quohgheecei6xoLo' });
    }
  };

  handleReCaptcha = (token) => {
    const { captcha } = this.state;

    if (!captcha) {
      // TODO: remove
      return this.setState({ captcha: token });
    }

    return null;
  };

  validateOnChange = (name, value) => {
    if (name === 'password') {
      const { validate } = this.state;
      this.setState({ validate: { ...validate, [name]: !!value && value.length > 5 } });
    }
    return null;
  };

  renderAuthError = (error) => (
    <Error>{ error }</Error>
  );

  render() {
    const { loading, error } = this.props;
    const {
      login,
      password,
      validate: {
        login: validateLogin,
        password: validatePassword,
      },
      isRendered,
    } = this.state;

    return (
      <GoogleReCaptchaProvider reCaptchaKey="6Lfj9woqAAAAAHCgjvsbm5LA0MR_yA4uCDI5gmJH">
        <FormWrapper autoComplete="on">
          <FieldWrapper key={`login${isRendered ? 'Rendered' : ''}`}>
            { !!error && this.renderAuthError(error) }
            <TextField
              onValidate={this.handleValidate}
              onChange={this.handleChange}
              value={login}
              size="m"
              disabled={loading}
              inputStyle="accent"
              type="newTel"
              name="login"
              placeholder="Телефон"
              autoComplete="on"
            />
          </FieldWrapper>
          <FieldWrapper key={`password${isRendered ? 'Rendered' : ''}`}>
            <TextField
              onChange={this.handleChange}
              value={password}
              size="m"
              disabled={loading}
              inputStyle="accent"
              type="password"
              name="password"
              placeholder="Пароль"
              autoComplete="on"
            />
          </FieldWrapper>
          <Button
            loading={loading}
            disabled={!validateLogin || !validatePassword}
            onClick={this.sendAuthData}
            text="Войти"
            size="l"
            type="primaryB"
            fullWidth
          />
          {/*<PasswordRecoveryButton onClick={this.handleRecoveryButtonClick}>*/}
          {/*  Восстановить доступ*/}
          {/*</PasswordRecoveryButton>*/}
        </FormWrapper>
        <GoogleReCaptcha onVerify={this.handleReCaptcha} />
      </GoogleReCaptchaProvider>
    );
  }
}

const mapStateToProps = ({ authService }) => ({
  loading: authService.loading,
  error: authService.error,
});

const mapDispatchToProps = {
  sendAuthAction: sendAuth,
  changeScreenStateAction: changeScreenState,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthForm);
